import axios from "axios";
import store from "./configStore";

export const AxiosService = ({ method, base_url, endPoint, payload, ContentType, isAuth }) => {
    const state = store.getState();
    let accessToken = state.utilReducers?.accessToken

    console.log('====================================');
    console.log("calling AxiosService: ",
        {
            "method": method,
            "url": base_url + endPoint,
            "body": payload,
            "ContentType": ContentType ? ContentType : 'application/json',
            "isAuth": isAuth,
            "token": isAuth ? accessToken : "",
        }
    );
    console.log('====================================');
    if (method === 'get') {
        return axios({
            method: method,
            url: base_url + endPoint,
            headers: isAuth ?
                {
                    'Content-Type': ContentType ? ContentType : 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                }
                :
                {
                    'Content-Type': ContentType ? ContentType : 'application/json',
                },
        });
    } else if (method === 'post' || method === 'put') {
        return axios({
            method: method,
            url: base_url + endPoint,
            data: payload,
            headers: isAuth ?
                {
                    'Content-Type': ContentType ? ContentType : 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                }
                :
                {
                    'Content-Type': ContentType ? ContentType : 'application/json',
                },
        });
    } else {
        return axios({
            method: method,
            url: base_url + endPoint,
            data: payload,
            headers: isAuth ?
                {
                    'Content-Type': ContentType ? ContentType : 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                }
                :
                {
                    'Content-Type': ContentType ? ContentType : 'application/json',
                },
        });
    }
};
