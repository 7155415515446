import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ children }) => {
    const AccessToken = useSelector((state) => state.utilReducers?.accessToken);

    // If no access token, redirect to login
    if (!AccessToken) {
        return <Navigate to="/login" replace />;
    }

    // Else render the protected component
    return children;
};

export default PrivateRoute;
