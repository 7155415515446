import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { influencerListFailure, influencerListSuccess } from '../../actions/influencerActions/influencerListActions';
import { setAccessToken } from '../../actions/utilsActions/utilsActions';
import store from '../../configStore';

function* influencerList({ payload }) {
    console.log('====================================');
    try {
        const response = yield call(AxiosService, {
            method: 'get',
            base_url: base_url,
            endPoint: endpoints.getInfluencerListByStatus + payload.statusFilter,
            payload: payload,
            isAuth: true,
        });
        console.log('====================================');
        console.log("INFLUENCER_LIST_SUCCESS status :", response.data.status);
        console.log("INFLUENCER_LIST_SUCCESS data :", response.data.response);
        console.log("INFLUENCER_LIST_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(
            influencerListSuccess({
                status: response?.data?.status,
                response: response?.data?.response,
                message: response?.data?.message
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("INFLUENCER_LIST_FAILURE status :", error?.response?.status);
        console.log("INFLUENCER_LIST_FAILURE data:", error?.response?.data);
        console.log("INFLUENCER_LIST_FAILURE error :", error);
        console.log('====================================');
        yield put(influencerListFailure({
            status: error?.response?.data?.status,
            response: {},
            message: error?.response?.data?.message
        }));
    }
}

function* influencerListSaga() {
    yield takeLatest(actionTypes.INFLUENCER_LIST_REQUEST, influencerList);
}

export default influencerListSaga;
