import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useLocation } from "react-router-dom";
import { configPropertyUpdate , configPropertyUpdateReset } from '../../store/actions/configPropertyActions/configPropertyUpdateActions';
import {useNavigate} from "react-router";
import {useToast} from "../../hooks/useToast";

function UpdateConfigProperty() {
    const dispatch = useDispatch();
    const location = useLocation();
    const Toast = useToast();
    const navigate = useNavigate();
    const { row } = location.state || {};

    const updateConfigData = useSelector((state) => state.configPropertyUpdateReducers );


    const [configPropertyData, setConfigPropertyData] = useState({
        configPropertiesId: '',
        type: '',
        name: '',
        value: '',
    });

    useEffect(() => {
        if (row) {
            setConfigPropertyData(row);
        }
    }, [row]);

    const handleChange = (e) => {
        setConfigPropertyData({
            ...configPropertyData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = () => {
        dispatch(configPropertyUpdate(configPropertyData));
    };

    useEffect(() => {
        if(updateConfigData.status === 200){
            dispatch(configPropertyUpdateReset())
            handleBack();
        }else if(updateConfigData.status === 403){
            Toast(updateConfigData.message, 'error' )
            dispatch(configPropertyUpdateReset())
        }else if(updateConfigData.status){
            Toast("Bad Request", 'info' )
            dispatch(configPropertyUpdateReset())
        }
    },[updateConfigData])


    const handleBack = () => {
        navigate(-1); // Navigates to the previous page
    };

    return (
        <div className=" bg-white text-2xl p-8 shadow-lg rounded-lg">
            <div className="text-4xl h-16  mb-6 text-center">
                Update Config Property
            </div>
            <form className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Type */}
                <div>
                    <label className="block font-medium mb-2">Type</label>
                    <input
                        type="text"
                        name="type"
                        value={configPropertyData.type}
                        onChange={handleChange}
                        className="border border-gray-300 rounded-lg p-2 w-full"
                        readOnly
                    />
                </div>
                {/* Name */}
                <div>
                    <label className="block font-medium mb-2">Name</label>
                    <input
                        type="text"
                        name="name"
                        value={configPropertyData.name}
                        onChange={handleChange}
                        className="border border-gray-300 rounded-lg p-2 w-full"
                        readOnly
                    />
                </div>
                {/* Value */}
                <div>
                    <label className="block font-medium mb-2">Value</label>
                    <input
                        type="text"
                        name="value"
                        value={configPropertyData.value}
                        onChange={handleChange}
                        className="border border-gray-300 rounded-lg p-2 w-full"
                    />
                </div>
            </form>
            {/* Submit Button */}
            <div className="text-center text-2xl mt-8">
                <button
                    type="button"
                    onClick={handleSubmit}
                    className="bg-blue-500 hover:opacity-90 text-white py-2 px-6 rounded-lg mr-4"
                >
                    Update
                </button>
                <button
                    type="button"
                    onClick={handleBack}
                    className="bg-gray-500 hover:opacity-90 text-white py-2 px-6 rounded-lg"
                >
                    Back
                </button>
            </div>

        </div>
    );
}

export default UpdateConfigProperty;
