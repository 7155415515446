import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { rateCardCreateFailure, rateCardCreateSuccess } from '../../actions/rateCardActions/rateCardCreateActions';
import store from '../../configStore';

function* rateCardCreate({ payload }) {
    console.log('====================================');
    try {
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url,
            endPoint: endpoints.createRateCard,
            payload: payload,
            isAuth: true,
        });
        console.log('====================================');
        console.log("CREATE_RATE_CARD_SUCCESS status :", response.data.status);
        console.log("CREATE_RATE_CARD_SUCCESS data :", response.data.response);
        console.log("CREATE_RATE_CARD_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(
            rateCardCreateSuccess({
                status: response?.data?.status,
                response: response?.data?.response,
                message: response?.data?.message
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("CREATE_RATE_CARD_FAILURE status :", error?.response?.status);
        console.log("CREATE_RATE_CARD_FAILURE data:", error?.response?.data);
        console.log("CREATE_RATE_CARD_FAILURE error :", error);
        console.log('====================================');
        yield put(rateCardCreateFailure({
            status: error?.response?.data?.status,
            response: {},
            message: error?.response?.data?.message
        }));
    }
}

function* rateCardCreateSaga() {
    yield takeLatest(actionTypes.CREATE_RATE_CARD_REQUEST, rateCardCreate);
}

export default rateCardCreateSaga;
