import { actionTypes } from '../../types';

export const approvedRejectedBrand = (payload) => ({
    type: actionTypes.APPROVED_REJECTED_BRAND_REQUEST,
    payload,
});

export const approvedRejectedBrandSuccess = (payload) => ({
    type: actionTypes.APPROVED_REJECTED_BRAND_SUCCESS,
    payload,
});

export const approvedRejectedBrandFailure = (payload) => ({
    type: actionTypes.APPROVED_REJECTED_BRAND_FAILURE,
    payload,
});

export const approvedRejectedBrandReset = () => ({
    type: actionTypes.APPROVED_REJECTED_BRAND_RESET,
});

export const approvedRejectedBrandResetAll = () => ({
    type: actionTypes.APPROVED_REJECTED_BRAND_RESET_ALL,
});
