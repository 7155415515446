import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const getAllCountryList = (payload) => ({
    type: actionTypes.GET_ALL_COUNTRIES_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const getAllCountryListSuccess = (payload) => ({
    type: actionTypes.GET_ALL_COUNTRIES_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const getAllCountryListFailure = (payload) => ({
    type: actionTypes.GET_ALL_COUNTRIES_FAILURE,
    payload,
});

export const getAllCountryListReset = () => ({
    type: actionTypes.GET_ALL_COUNTRIES_RESET,
});

// Action creator for resetting all OTP generation reducer
export const getAllCountryListResetAll = () => ({
    type: actionTypes.GET_ALL_COUNTRIES_RESET_ALL,
});
