import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setAccessToken } from '../../store/actions/utilsActions/utilsActions'

function Dashboard() {
    const dispatch = useDispatch();

    const accessToken = useSelector((state) => state.utilReducers.accessToken);


    return (
        <div className="">

            <div className="text-4xl ml-2 mb-4 leading-[40px] ">
            Dashboard Page!!

            </div>
        </div>

    )
}

export default Dashboard
