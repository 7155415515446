import { actionTypes } from "../../types";

export const setAccessToken = (payload) => ({
    type: actionTypes.SET_ACCESS_TOKEN,
    payload: payload
});

export const setRefreshToken = (payload) => ({
    type: actionTypes.SET_REFRESH_TOKEN,
    payload: payload
});

export const setUserId = (payload) => ({
    type: actionTypes.SET_USER_ID,
    payload: payload
});

export const logout = () => ({
    type: actionTypes.USER_LOGOUT
});

export const userLatitute = (payload) => ({
    type: actionTypes.USER_LATITUTE,
    payload: payload
});

export const userLongitude = (payload) => ({
    type: actionTypes.USER_LONGITUDE,
    payload: payload
});

export const userRoleId = (payload) => ({
    type: actionTypes.USER_ROLE_ID,
    payload: payload
});

export const userCategory = (payload) => ({
    type: actionTypes.USER_CATEGORY,
    payload: payload
});

export const setVerifiedPhoneNo = (payload) => ({
    type: actionTypes.SET_VERIFIED_PHONE_NO,
    payload: payload
});

export const setSelectedPlan = (payload) => ({
    type: actionTypes.SET_SELECTED_PLAN,
    payload: payload
});

export const setEmail = (payload) => ({
    type: actionTypes.SET_EMAIL,
    payload: payload
});

export const setJwtToken = (payload) => ({
    type: actionTypes.SET_JWT_TOKEN,
    payload: payload
});
