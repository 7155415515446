import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const rateCardList = (payload) => ({
    type: actionTypes.RATE_CARD_LIST_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const rateCardListSuccess = (payload) => ({
    type: actionTypes.RATE_CARD_LIST_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const rateCardListFailure = (payload) => ({
    type: actionTypes.RATE_CARD_LIST_FAILURE,
    payload,
});

export const rateCardListReset = () => ({
    type: actionTypes.RATE_CARD_LIST_RESET,
});

// Action creator for resetting all OTP generation reducer
export const rateCardListResetAll = () => ({
    type: actionTypes.RATE_CARD_LIST_RESET_ALL,
});
