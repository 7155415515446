import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const configPropertyList = (payload) => ({
    type: actionTypes.CONFIG_PROPERTY_LIST_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const configPropertyListSuccess = (payload) => ({
    type: actionTypes.CONFIG_PROPERTY_LIST_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const configPropertyListFailure = (payload) => ({
    type: actionTypes.CONFIG_PROPERTY_LIST_FAILURE,
    payload,
});

export const configPropertyListReset = () => ({
    type: actionTypes.CONFIG_PROPERTY_LIST_RESET,
});

// Action creator for resetting all OTP generation reducer
export const configPropertyListResetAll = () => ({
    type: actionTypes.CONFIG_PROPERTY_LIST_RESET_ALL,
});
