import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const getAllRegionList = (payload) => ({
    type: actionTypes.GET_ALL_REGIONS_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const getAllRegionListSuccess = (payload) => ({
    type: actionTypes.GET_ALL_REGIONS_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const getAllRegionListFailure = (payload) => ({
    type: actionTypes.GET_ALL_REGIONS_FAILURE,
    payload,
});

export const getAllRegionListReset = () => ({
    type: actionTypes.GET_ALL_REGIONS_RESET,
});

// Action creator for resetting all OTP generation reducer
export const getAllRegionListResetAll = () => ({
    type: actionTypes.GET_ALL_REGIONS_RESET_ALL,
});
