import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const campaignById = (payload) => ({
    type: actionTypes.CAMPAIGN_BY_ID_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const campaignByIdSuccess = (payload) => ({
    type: actionTypes.CAMPAIGN_BY_ID_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const campaignByIdFailure = (payload) => ({
    type: actionTypes.CAMPAIGN_BY_ID_FAILURE,
    payload,
});

export const campaignByIdReset = () => ({
    type: actionTypes.CAMPAIGN_BY_ID_RESET,
});

// Action creator for resetting all OTP generation reducer
export const campaignByIdResetAll = () => ({
    type: actionTypes.CAMPAIGN_BY_ID_RESET_ALL,
});
