import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const getAllAgeGroupList = (payload) => ({
    type: actionTypes.GET_ALL_AGE_GROUP_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const getAllAgeGroupListSuccess = (payload) => ({
    type: actionTypes.GET_ALL_AGE_GROUP_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const getAllAgeGroupListFailure = (payload) => ({
    type: actionTypes.GET_ALL_AGE_GROUP_FAILURE,
    payload,
});

export const getAllAgeGroupListReset = () => ({
    type: actionTypes.GET_ALL_AGE_GROUP_RESET,
});

// Action creator for resetting all OTP generation reducer
export const getAllAgeGroupListResetAll = () => ({
    type: actionTypes.GET_ALL_AGE_GROUP_RESET_ALL,
});
