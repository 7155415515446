import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import utilReducers from "./utilReducers/utilReducers";
import loginReducers from "./authReducers/loginReducers";
import influencerListReducers from "./influencerReducers/influencerListReducers";
import brandListReducers from "./brandReducers/brandListReducers";
import approvedRejectedInfluencerReducers from "./influencerReducers/approvedRejectedInfluencerReducers";
import approvedRejectedBrandReducers from "./brandReducers/approvedRejectedBrandReducers";
import campaignListReducers from "./campaignReducers/campaignListReducers";
import approvedRejectedCampaignReducers from "./campaignReducers/approvedRejectedCampaignReducers";
import campaignByIdReducers from "./campaignReducers/campaignByIdReducers";
import rateCardListReducers from "./rateCardReducers/rateCardListReducers";
import rateCardCreateReducers from "./rateCardReducers/rateCardCreateReducers";
import rateCardUpdateReducers from "./rateCardReducers/rateCardUpdateReducers";
import configPropertyListReducers from "./configPropertyReducers/configPropertyListReducers";
import configPropertyUpdateReducers from "./configPropertyReducers/configPropertyUpdateReducers";
import getAllAgeGroupReducers from "./publicReducers/getAllAgeGroupReducers";
import getAllNicheReducers from "./publicReducers/getAllNicheReducers";
import getAllRegionReducers from "./publicReducers/getAllRegionsReducers";
import getAllStateReducers from "./publicReducers/getAllStateReducers";
import getAllCountryReducers from "./publicReducers/getAllCountryReducers";



const persistConfig = {
	key: 'root',
	storage,
	stateReconciler: autoMergeLevel2,
	whitelist: [
		// add reducers to persist data
		'utilReducers',
	],
};

const appReducer = combineReducers({
	utilReducers: utilReducers,
	loginReducers: loginReducers,
	influencerListReducers: influencerListReducers,
	approvedRejectedInfluencerReducers: approvedRejectedInfluencerReducers,
	brandListReducers: brandListReducers,
	approvedRejectedBrandReducers:approvedRejectedBrandReducers,
	campaignListReducers : campaignListReducers,
	approvedRejectedCampaignReducers:approvedRejectedCampaignReducers,
	campaignByIdReducers : campaignByIdReducers,
	rateCardListReducers : rateCardListReducers,
	rateCardCreateReducers : rateCardCreateReducers,
	rateCardUpdateReducers : rateCardUpdateReducers,
	configPropertyListReducers: configPropertyListReducers,
	configPropertyUpdateReducers: configPropertyUpdateReducers,
	getAllAgeGroupReducers: getAllAgeGroupReducers,
    getAllNicheReducers: getAllNicheReducers,
    getAllRegionReducers: getAllRegionReducers,
    getAllStateReducers: getAllStateReducers,
    getAllCountryReducers: getAllCountryReducers,
})

const rootReducers = (state, action) => {
	if (action.type === 'USER_LOGOUT') {
		return appReducer(undefined, action)
	}

	return appReducer(state, action)
}

export default persistReducer(persistConfig, rootReducers);
