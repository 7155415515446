export const actionTypes = {
	// Utils
	USER_LOGOUT: 'USER_LOGOUT',
	SET_ACCESS_TOKEN: 'SET_ACCESS_TOKEN',
	SET_REFRESH_TOKEN: 'SET_REFRESH_TOKEN',
	SET_USER_ID: 'SET_USER_ID',
	SET_VERIFIED_PHONE_NO: 'SET_VERIFIED_PHONE_NO',
	SET_SELECTED_PLAN: 'SET_SELECTED_PLAN',
	SET_EMAIL: 'SET_EMAIL',
	SET_JWT_TOKEN: 'SET_JWT_TOKEN',


	// Auth
	LOGIN_REQUEST: 'LOGIN_REQUEST',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_FAILURE: 'LOGIN_FAILURE',
	LOGIN_RESET: 'LOGIN_RESET',
	LOGIN_RESET_ALL: 'LOGIN_RESET_ALL',

	// influencer
	INFLUENCER_LIST_REQUEST: 'INFLUENCER_LIST_REQUEST',
	INFLUENCER_LIST_SUCCESS: 'INFLUENCER_LIST_SUCCESS',
	INFLUENCER_LIST_FAILURE: 'INFLUENCER_LIST_FAILURE',
	INFLUENCER_LIST_RESET: 'INFLUENCER_LIST_RESET',
	INFLUENCER_LIST_RESET_ALL: 'INFLUENCER_LIST_RESET_ALL',


	APPROVED_REJECTED_INFLUENCER_REQUEST: 'APPROVED_REJECTED_INFLUENCER_REQUEST',
	APPROVED_REJECTED_INFLUENCER_SUCCESS: 'APPROVED_REJECTED_INFLUENCER_SUCCESS',
	APPROVED_REJECTED_INFLUENCER_FAILURE: 'APPROVED_REJECTED_INFLUENCER_FAILURE',
	APPROVED_REJECTED_INFLUENCER_RESET: 'APPROVED_REJECTED_INFLUENCER_RESET',
	APPROVED_REJECTED_INFLUENCER_RESET_ALL: 'APPROVED_REJECTED_INFLUENCER_RESET_ALL',




	// brand
	BRAND_LIST_REQUEST: 'BRAND_LIST_REQUEST',
	BRAND_LIST_SUCCESS: 'BRAND_LIST_SUCCESS',
	BRAND_LIST_FAILURE: 'BRAND_LIST_FAILURE',
	BRAND_LIST_RESET: 'BRAND_LIST_RESET',
	BRAND_LIST_RESET_ALL: 'BRAND_LIST_RESET_ALL',


	APPROVED_REJECTED_BRAND_REQUEST: 'APPROVED_REJECTED_BRAND_REQUEST',
	APPROVED_REJECTED_BRAND_SUCCESS: 'APPROVED_REJECTED_BRAND_SUCCESS',
	APPROVED_REJECTED_BRAND_FAILURE: 'APPROVED_REJECTED_BRAND_FAILURE',
	APPROVED_REJECTED_BRAND_RESET: 'APPROVED_REJECTED_BRAND_RESET',
	APPROVED_REJECTED_BRAND_RESET_ALL: 'APPROVED_REJECTED_BRAND_RESET_ALL',

// 	campaign
	CAMPAIGN_LIST_REQUEST: 'CAMPAIGN_LIST_REQUEST',
	CAMPAIGN_LIST_SUCCESS: 'CAMPAIGN_LIST_SUCCESS',
	CAMPAIGN_LIST_FAILURE: 'CAMPAIGN_LIST_FAILURE',
	CAMPAIGN_LIST_RESET: 'CAMPAIGN_LIST_RESET',
	CAMPAIGN_LIST_RESET_ALL: 'CAMPAIGN_LIST_RESET_ALL',

	CAMPAIGN_BY_ID_REQUEST: 'CAMPAIGN_BY_ID_REQUEST',
	CAMPAIGN_BY_ID_SUCCESS: 'CAMPAIGN_BY_ID_SUCCESS',
	CAMPAIGN_BY_ID_FAILURE: 'CAMPAIGN_BY_ID_FAILURE',
	CAMPAIGN_BY_ID_RESET: 'CAMPAIGN_BY_ID_RESET',
	CAMPAIGN_BY_ID_RESET_ALL: 'CAMPAIGN_BY_ID_RESET_ALL',


	APPROVED_REJECTED_CAMPAIGN_REQUEST: 'APPROVED_REJECTED_CAMPAIGN_REQUEST',
	APPROVED_REJECTED_CAMPAIGN_SUCCESS: 'APPROVED_REJECTED_CAMPAIGN_SUCCESS',
	APPROVED_REJECTED_CAMPAIGN_FAILURE: 'APPROVED_REJECTED_CAMPAIGN_FAILURE',
	APPROVED_REJECTED_CAMPAIGN_RESET: 'APPROVED_REJECTED_CAMPAIGN_RESET',
	APPROVED_REJECTED_CAMPAIGN_RESET_ALL: 'APPROVED_REJECTED_CAMPAIGN_RESET_ALL',

// 	Rate Card

	RATE_CARD_LIST_REQUEST: 'RATE_CARD_LIST_REQUEST',
    RATE_CARD_LIST_SUCCESS: 'RATE_CARD_LIST_SUCCESS',
    RATE_CARD_LIST_FAILURE: 'RATE_CARD_LIST_FAILURE',
    RATE_CARD_LIST_RESET: 'RATE_CARD_LIST_RESET',
	RATE_CARD_LIST_RESET_ALL: 'RATE_CARD_LIST_RESET_ALL',

// 	Create rate card
	CREATE_RATE_CARD_REQUEST: 'CREATE_RATE_CARD_REQUEST',
    CREATE_RATE_CARD_SUCCESS: 'CREATE_RATE_CARD_SUCCESS',
    CREATE_RATE_CARD_FAILURE: 'CREATE_RATE_CARD_FAILURE',
    CREATE_RATE_CARD_RESET: 'CREATE_RATE_CARD_RESET',
    CREATE_RATE_CARD_RESET_ALL: 'CREATE_RATE_CARD_RESET_ALL',

// 	Update rate card
	UPDATE_RATE_CARD_REQUEST: 'UPDATE_RATE_CARD_REQUEST',
    UPDATE_RATE_CARD_SUCCESS: 'UPDATE_RATE_CARD_SUCCESS',
    UPDATE_RATE_CARD_FAILURE: 'UPDATE_RATE_CARD_FAILURE',
    UPDATE_RATE_CARD_RESET: 'UPDATE_RATE_CARD_RESET',
    UPDATE_RATE_CARD_RESET_ALL: 'UPDATE_RATE_CARD_RESET_ALL',

// 	Config Property
	CONFIG_PROPERTY_LIST_REQUEST: 'CONFIG_PROPERTY_LIST_REQUEST',
    CONFIG_PROPERTY_LIST_SUCCESS: 'CONFIG_PROPERTY_LIST_SUCCESS',
    CONFIG_PROPERTY_LIST_FAILURE: 'CONFIG_PROPERTY_LIST_FAILURE',
    CONFIG_PROPERTY_LIST_RESET: 'CONFIG_PROPERTY_LIST_RESET',
    CONFIG_PROPERTY_LIST_RESET_ALL: 'CONFIG_PROPERTY_LIST_RESET_ALL',

    UPDATE_CONFIG_PROPERTY_REQUEST : 'UPDATE_CONFIG_PROPERTY_REQUEST',
	UPDATE_CONFIG_PROPERTY_SUCCESS : 'UPDATE_CONFIG_PROPERTY_SUCCESS',
    UPDATE_CONFIG_PROPERTY_FAILURE : 'UPDATE_CONFIG_PROPERTY_FAILURE',
    UPDATE_CONFIG_PROPERTY_RESET : 'UPDATE_CONFIG_PROPERTY_RESET',
    UPDATE_CONFIG_PROPERTY_RESET_ALL : 'UPDATE_CONFIG_PROPERTY_RESET_ALL',


	GET_ALL_REGIONS_REQUEST : 'GET_ALL_REGIONS_REQUEST',
	GET_ALL_REGIONS_SUCCESS : 'GET_ALL_REGIONS_SUCCESS',
    GET_ALL_REGIONS_FAILURE : 'GET_ALL_REGIONS_FAILURE',
    GET_ALL_REGIONS_RESET : 'GET_ALL_REGIONS_RESET',
    GET_ALL_REGIONS_RESET_ALL : 'GET_ALL_REGIONS_RESET_ALL',

	GET_ALL_COUNTRIES_REQUEST : 'GET_ALL_COUNTRIES_REQUEST',
    GET_ALL_COUNTRIES_SUCCESS : 'GET_ALL_COUNTRIES_SUCCESS',
    GET_ALL_COUNTRIES_FAILURE : 'GET_ALL_COUNTRIES_FAILURE',
    GET_ALL_COUNTRIES_RESET : 'GET_ALL_COUNTRIES_RESET',
	GET_ALL_COUNTRIES_RESET_ALL : 'GET_ALL_COUNTRIES_RESET_ALL',

    GET_ALL_STATE_REQUEST : 'GET_ALL_STATE_REQUEST',
    GET_ALL_STATE_SUCCESS : 'GET_ALL_STATE_SUCCESS',
    GET_ALL_STATE_FAILURE : 'GET_ALL_STATE_FAILURE',
    GET_ALL_STATE_RESET : 'GET_ALL_STATE_RESET',
	GET_ALL_STATE_RESET_ALL : 'GET_ALL_STATE_RESET_ALL',

	GET_ALL_AGE_GROUP_REQUEST : 'GET_ALL_AGE_GROUP_REQUEST',
    GET_ALL_AGE_GROUP_SUCCESS : 'GET_ALL_AGE_GROUP_SUCCESS',
    GET_ALL_AGE_GROUP_FAILURE : 'GET_ALL_AGE_GROUP_FAILURE',
    GET_ALL_AGE_GROUP_RESET : 'GET_ALL_AGE_GROUP_RESET',
	GET_ALL_AGE_GROUP_RESET_ALL : 'GET_ALL_AGE_GROUP_RESET_ALL',

	GET_ALL_NICHE_REQUEST : 'GET_ALL_NICHE_REQUEST',
    GET_ALL_NICHE_SUCCESS : 'GET_ALL_NICHE_SUCCESS',
    GET_ALL_NICHE_FAILURE : 'GET_ALL_NICHE_FAILURE',
    GET_ALL_NICHE_RESET : 'GET_ALL_NICHE_RESET',
	GET_ALL_NICHE_RESET_ALL : 'GET_ALL_NICHE_RESET_ALL',



}

