import { actionTypes } from '../../types';

export const approvedRejectedCampaign = (payload) => ({
    type: actionTypes.APPROVED_REJECTED_CAMPAIGN_REQUEST,
    payload,
});

export const approvedRejectedCampaignSuccess = (payload) => ({
    type: actionTypes.APPROVED_REJECTED_CAMPAIGN_SUCCESS,
    payload,
});

export const approvedRejectedCampaignFailure = (payload) => ({
    type: actionTypes.APPROVED_REJECTED_CAMPAIGN_FAILURE,
    payload,
});

export const approvedRejectedCampaignReset = () => ({
    type: actionTypes.APPROVED_REJECTED_CAMPAIGN_RESET,
});

export const approvedRejectedCampaignResetAll = () => ({
    type: actionTypes.APPROVED_REJECTED_CAMPAIGN_RESET_ALL,
});
