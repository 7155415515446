import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const influencerList = (payload) => ({
    type: actionTypes.INFLUENCER_LIST_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const influencerListSuccess = (payload) => ({
    type: actionTypes.INFLUENCER_LIST_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const influencerListFailure = (payload) => ({
    type: actionTypes.INFLUENCER_LIST_FAILURE,
    payload,
});

export const influencerListReset = () => ({
    type: actionTypes.INFLUENCER_LIST_RESET,
});

// Action creator for resetting all OTP generation reducer
export const influencerListResetAll = () => ({
    type: actionTypes.INFLUENCER_LIST_RESET_ALL,
});
