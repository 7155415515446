import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const getAllNicheList = (payload) => ({
    type: actionTypes.GET_ALL_NICHE_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const getAllNicheListSuccess = (payload) => ({
    type: actionTypes.GET_ALL_NICHE_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const getAllNicheListFailure = (payload) => ({
    type: actionTypes.GET_ALL_NICHE_FAILURE,
    payload,
});

export const getAllNicheListReset = () => ({
    type: actionTypes.GET_ALL_NICHE_RESET,
});

// Action creator for resetting all OTP generation reducer
export const getAllNicheListResetAll = () => ({
    type: actionTypes.GET_ALL_NICHE_RESET_ALL,
});
