import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const approvedRejectedInfluencer = (payload) => ({
    type: actionTypes.APPROVED_REJECTED_INFLUENCER_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const approvedRejectedInfluencerSuccess = (payload) => ({
    type: actionTypes.APPROVED_REJECTED_INFLUENCER_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const approvedRejectedInfluencerFailure = (payload) => ({
    type: actionTypes.APPROVED_REJECTED_INFLUENCER_FAILURE,
    payload,
});

export const approvedRejectedInfluencerReset = () => ({
    type: actionTypes.APPROVED_REJECTED_INFLUENCER_RESET,
});

// Action creator for resetting all OTP generation reducer
export const approvedRejectedInfluencerResetAll = () => ({
    type: actionTypes.APPROVED_REJECTED_INFLUENCER_RESET_ALL,
});
