import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const getAllStateList = (payload) => ({
    type: actionTypes.GET_ALL_STATE_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const getAllStateListSuccess = (payload) => ({
    type: actionTypes.GET_ALL_STATE_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const getAllStateListFailure = (payload) => ({
    type: actionTypes.GET_ALL_STATE_FAILURE,
    payload,
});

export const getAllStateListReset = () => ({
    type: actionTypes.GET_ALL_STATE_RESET,
});

// Action creator for resetting all OTP generation reducer
export const getAllStateListResetAll = () => ({
    type: actionTypes.GET_ALL_STATE_RESET_ALL,
});
