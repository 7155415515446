import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { campaignByIdFailure, campaignByIdSuccess } from '../../actions/campaignActions/campaignByIdActions';
import store from '../../configStore';

function* campaignById({ payload }) {
    console.log('====================================');
    try {
        const response = yield call(AxiosService, {
            method: 'get',
            base_url: base_url,
            endPoint: endpoints.getCampaignById + payload.campaignId,
            payload: payload,
            isAuth: true,
        });
        console.log('====================================');
        console.log("CAMPAIGN_BY_ID_SUCCESS status :", response.data.status);
        console.log("CAMPAIGN_BY_ID_SUCCESS data :", response.data.response);
        console.log("CAMPAIGN_BY_ID_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(
            campaignByIdSuccess({
                status: response?.data?.status,
                response: response?.data?.response,
                message: response?.data?.message
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("CAMPAIGN_BY_ID_FAILURE status :", error?.response?.status);
        console.log("CAMPAIGN_BY_ID_FAILURE data:", error?.response?.data);
        console.log("CAMPAIGN_BY_ID_FAILURE error :", error);
        console.log('====================================');
        yield put(campaignByIdFailure({
            status: error?.response?.data?.status,
            response: {},
            message: error?.response?.data?.message
        }));
    }
}

function* campaignByIdSaga() {
    yield takeLatest(actionTypes.CAMPAIGN_BY_ID_REQUEST, campaignById);
}

export default campaignByIdSaga;
