import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { rateCardListFailure, rateCardListSuccess } from '../../actions/rateCardActions/rateCardListActions';
import store from '../../configStore';

function* rateCardList({ payload }) {
    console.log('====================================');
    try {
        const response = yield call(AxiosService, {
            method: 'get',
            base_url: base_url,
            endPoint: endpoints.getAllRateCard,
            payload: payload,
            isAuth: true,
        });
        console.log('====================================');
        console.log("RATE_CARD_LIST_SUCCESS status :", response.data.status);
        console.log("RATE_CARD_LIST_SUCCESS data :", response.data.response);
        console.log("RATE_CARD_LIST_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(
            rateCardListSuccess({
                status: response?.data?.status,
                response: response?.data?.response,
                message: response?.data?.message
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("RATE_CARD_LIST_FAILURE status :", error?.response?.status);
        console.log("RATE_CARD_LIST_FAILURE data:", error?.response?.data);
        console.log("RATE_CARD_LIST_FAILURE error :", error);
        console.log('====================================');
        yield put(rateCardListFailure({
            status: error?.response?.data?.status,
            response: {},
            message: error?.response?.data?.message
        }));
    }
}

function* rateCardListSaga() {
    yield takeLatest(actionTypes.RATE_CARD_LIST_REQUEST, rateCardList);
}

export default rateCardListSaga;
