import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const rateCardCreate = (payload) => ({
    type: actionTypes.CREATE_RATE_CARD_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const rateCardCreateSuccess = (payload) => ({
    type: actionTypes.CREATE_RATE_CARD_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const rateCardCreateFailure = (payload) => ({
    type: actionTypes.CREATE_RATE_CARD_FAILURE,
    payload,
});

export const rateCardCreateReset = () => ({
    type: actionTypes.CREATE_RATE_CARD_RESET,
});

// Action creator for resetting all OTP generation reducer
export const rateCardCreateResetAll = () => ({
    type: actionTypes.CREATE_RATE_CARD_RESET_ALL,
});
