import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const rateCardUpdate = (payload) => ({
    type: actionTypes.UPDATE_RATE_CARD_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const rateCardUpdateSuccess = (payload) => ({
    type: actionTypes.UPDATE_RATE_CARD_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const rateCardUpdateFailure = (payload) => ({
    type: actionTypes.UPDATE_RATE_CARD_FAILURE,
    payload,
});

export const rateCardUpdateReset = () => ({
    type: actionTypes.UPDATE_RATE_CARD_RESET,
});

// Action creator for resetting all OTP generation reducer
export const rateCardUpdateResetAll = () => ({
    type: actionTypes.UPDATE_RATE_CARD_RESET_ALL,
});
