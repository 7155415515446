import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const configPropertyUpdate = (payload) => ({
    type: actionTypes.UPDATE_CONFIG_PROPERTY_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const configPropertyUpdateSuccess = (payload) => ({
    type: actionTypes.UPDATE_CONFIG_PROPERTY_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const configPropertyUpdateFailure = (payload) => ({
    type: actionTypes.UPDATE_CONFIG_PROPERTY_FAILURE,
    payload,
});

export const configPropertyUpdateReset = () => ({
    type: actionTypes.UPDATE_CONFIG_PROPERTY_RESET,
});

// Action creator for resetting all OTP generation reducer
export const configPropertyUpdateResetAll = () => ({
    type: actionTypes.UPDATE_CONFIG_PROPERTY_RESET_ALL,
});
