import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {campaignById} from "../../store/actions/campaignActions/campaignByIdActions";
import {campaignListReset} from "../../store/actions/campaignActions/campaignListActions";
import {useNavigate} from "react-router";

const CampaignDetails = ( ) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const campaignReducer = useSelector(state => state.campaignByIdReducers);

    const { id } = useParams();

    const [data, setData] = useState({});


    console.log( 'Campaign',id);

    useEffect(() => {
        dispatch(campaignById({'campaignId':id} ));
    }, []);

    useEffect(() => {
        if (campaignReducer?.status === 200) {
            console.log("campaign data", campaignReducer.data);
            setData(campaignReducer.data);
            dispatch(campaignListReset());
        } else if (campaignReducer?.status) {
            dispatch(campaignListReset());
        }
    }, [campaignReducer]);


    return (
        <div className="max-w-[100%] mx-auto p-5 text-gray-800 bg-gray-100 rounded-lg shadow-lg font-sans">
            {/* Back Button */}
            <div className="flex text-2xl items-center ">
                <button
                    onClick={() => navigate(-1)}
                    className="flex items-center gap-2 px-4 py-2 text-white bg-[#2c3e50] hover:bg-[#34495e] rounded-lg shadow-md transition-all duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#34db4d]"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-5 h-5"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5L8.25 12l7.5-7.5"
                        />
                    </svg>
                    Back
                </button>
            </div>

            <h2 className="text-center mb-8 text-4xl font-semibold text-[#2c3e50] border-b-2 border-[#34db4d] pb-2">
                Campaign Details
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 text-lg text-gray-700">
                <Detail label="Campaign ID" value={data.campaignId}/>
                <Detail label="Name" value={data.name}/>
                <Detail label="Purpose" value={data.purpose}/>
                <Detail label="Platform" value={data.platform}/>
                <Detail label="Referral Code" value={data.referralCode}/>
                <Detail label="Campaign Type" value={data.campaignType}/>
                <Detail label="Campaign Status" value={data.campaignStatus}/>
                <Detail label="Start Date" value={data.startDate}/>
                <Detail label="End Date" value={data.endDate}/>
                <Detail label="Dialogue" value={data.dialogue} isLongText/>
                <Detail label="Script" value={data.script} isLongText/>
                <Detail label="Dialogue" value={data.dialogue} isLongText />
                <Detail label="Payment Status" value={data.paymentStatus}/>
                <Detail label="Is Location Required" value={data.isLocationRequired ? 'Yes' : 'No'}/>
                <Detail label="Brand Approval Required" value={data.isBrandApprovalRequired ? 'Yes' : 'No'}/>
                <Detail label="Upload Duration" value={data.uploadDuration}/>
                <Detail label="Region" value={data.region?.name || 'N/A'}/>
                <Detail label="Country" value={data.country?.countryName || 'N/A'}/>
                <Detail label="State" value={data.state?.name || 'N/A'}/>
                <Detail label="Campaign Gender" value={data.campaignGender}/>
                <Detail label="Target Views" value={data.targetViews}/>
                <Detail label="Influencer Type" value={data.influencerType}/>
                <Detail label="Estimated Budget" value={`$${data.estimatedBudget}`}/>
                <Detail label="Brand Name" value={data.brand?.companyName || 'N/A'}/>
                <Detail label="Wallet Balance"
                        value={`${data.brand?.wallet?.balance} ${data.brand?.wallet?.currency}`}/>
                <Detail label="User Name"
                        value={`${data.brand?.wallet?.user?.firstName} ${data.brand?.wallet?.user?.lastName}`}/>
                <Detail label="User Email" value={data.brand?.wallet?.user?.email}/>
                <Detail label="User Type" value={data.brand?.wallet?.user?.userType}/>
            </div>
        </div>
    );
};


const Detail = ({label, value, isLongText}) => (
    <div
        className={`flex flex-col text-2xl p-3 bg-white rounded-md shadow-md ${isLongText ? 'max-h-[150px] overflow-auto' : ''}`}>
        <span className="font-bold text-[#2c3e50] mb-1">{label}:</span>
        <span className="text-gray-600">{value || 'N/A'}</span>
    </div>
);
export default CampaignDetails;
